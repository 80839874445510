import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

const CutOffWarningModal = ({ isOpen, toggle }) => {
  return (
    <Modal centered backdrop="static" isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <span style={{ color: "black", fontWeight: "bold" }}>Important Notice</span>
      </ModalHeader>
      <ModalBody>
        <p style={{ color: "red", fontSize: "16px", fontWeight: "bold" }}>
          Please note our Cut-Off is 15:45. Any kit order after this time will automatically be sent as a sameday.
        </p>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={toggle}>
          OK
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CutOffWarningModal;
