import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import CutOffWarningModal from "./Modal/CutOffWarningModal";
import SameDateModal from "./Modal/sameDateModal";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Input,
  Button,
} from "reactstrap";
import Select from "react-select";
import Footer from "../../../GlobalComponents/Footer";
import Navbar from "../Navbar";
import {
  repsBookingActions,
  kitActions,
  setActions,
  operationActions,
} from "../../../../actions";
import dateFormat from "dateformat";
import AddSurgeon from "../../Admin/Surgeon/Add";
import AddHospital from "../../Admin/Hospitals/Add/Index";
import UpdateHospital from "../../Admin/Hospitals/Update/Index";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import DatePicker from "react-datepicker";
import { bookingService } from "../../../../services/booking.services";
import { repsBookingService } from "../../../../services/repsBooking.services";
import { surgeonService } from "../../../../services/surgeon.services";
import { history } from "../../../../helpers";
import { ReactComponent as Spinner } from "../../../../assets/images/Spinner-1s-200px.svg";

const deliverySlots = [
  { value: "Pre 9AM", label: "Pre 9AM" },
  { value: "Pre 10AM", label: "Pre 10AM" },
  { value: "Pre 12", label: "Pre 12" },
  { value: "Same Day", label: "Same Day" },
];

const operationSlots = [
  { value: "AM", label: "AM" },
  { value: "PM", label: "PM" },
  { value: "TBD", label: "TBD" },
];

const bilateralCaseList = [
  { value: "TBC", label: "TBC" },
  { value: "YES", label: "YES" },
  { value: "NO", label: "NO" },
];

class Add extends Component {
  constructor(props) {
    super(props);
    // reset login status
    this.state = {
      user: JSON.parse(localStorage.getItem("user")),
      kitSetErrorFlag: 0,
      updateBookingInfo: {
        holidays: [],
        sameDatesModal: false,
        sameDatesInfo: [],
        id: "",
        booking_type: "",
        hospitalId: "",
        hospital_address: "",
        hospital_department: "",
        po_number: "",
        operation_name: "",
        shipping_charge: "",
        additional_notes: "",
        assigned_rep: "",
        additional_reps: [],
        delivery_date: new Date("2022-05-10"),
        delivery_date_slot: "11 am",
        operation_date: new Date("2022-05-11"),
        operation_date_slot: "12 pm",
        collection_date: new Date("2022-05-14"),
        return_date: "",
        kitData: [],
        kitSetData: [],
        log_status: "",
        log_by_id: "",
        surgeonData: [],
        other_opration: "",
        po_check: false,
        pickup_location_checkbox: false,
        pickup_location_text: "",
        bilateral_case: "",
      },
      bookingInfo: {},
      changesMade: false,
      selectedOperation: {},
      selectedHospital: {},
      selectedDeliverySlot: [],
      selectedOperationSlots: [],
      selectedRep: [],
      selectedAdditionalRep: [],
      reps: [],
      hospital_hire_charge: "",
      additionalReps: [],
      hospitals: [],
      hospitalDetails: [],
      repsDetails: [],
      hospitalAddress: [],
      hospitalContactPerson: "",
      hospitalContactPersonEmail: "",
      hospitalContactPersonPhone: "",
      searchHospital: "",
      searchReps: "",
      searchOps: "",
      kits: [],
      kitListFlag: 0,
      selectedKit: [],
      kitWithKitSet: [],
      kitSetDetailModal: false,
      kitDetails: [],
      kitDetailsClickFlag: 0,
      bookingType: [
        { value: "loan", label: "Loan" },
        { value: "consignment", label: "Consignment" },
      ],
      selectedBookingType: [],
      selectedSurgeon: [],
      surgeonList: [],
      operationsList: [],
      addSurgeonModal: false,
      quantityError: false,
      addNewHospitalModalProp: false,
      suggestedKits: [],
      editHospitalModal: false,
      editHospitalId: 0,
      hospitalDepartment: [],
      isEditable: false,
      editablePoNumber: "",
      surgeonNoteDetails: "",
      isLoading: false,
      selectedBilateralCase: [],
      proceed: false,
      checkDateFlag: false,
      showCutOffWarning: false,
    };

    this.getList = this.getList.bind(this);
    this.getSingleBooking = this.getSingleBooking.bind(this);
    this.getHospitalAddressList = this.getHospitalAddressList.bind(this);
    this.showHospitalsList = this.showHospitalsList.bind(this);
    this.showOperationsList = this.showOperationsList.bind(this);
    this.handleUpdateBookingInfo = this.handleUpdateBookingInfo.bind(this);
    this.setSelectedOptionOperation =
      this.setSelectedOptionOperation.bind(this);
    this.setSelectedOptionHospital = this.setSelectedOptionHospital.bind(this);
    this.setSelectedOptionDeliverySlot =
      this.setSelectedOptionDeliverySlot.bind(this);
    this.setSelectedOptionOperationSlots =
      this.setSelectedOptionOperationSlots.bind(this);
    this.setSelectedOptionRep = this.setSelectedOptionRep.bind(this);
    this.setSelectedOptionAdditionalRep =
      this.setSelectedOptionAdditionalRep.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleBookingAddress = this.handleBookingAddress.bind(this);
    this.setSelectedOptionKit = this.setSelectedOptionKit.bind(this);
    this.handleAddKit = this.handleAddKit.bind(this);
    this.handelAddRemovSet = this.handelAddRemovSet.bind(this);
    this.handleHireCharge = this.handleHireCharge.bind(this);
    this.handleKitDetails = this.handleKitDetails.bind(this);
    this.showKitDetails = this.showKitDetails.bind(this);
    this.kitSetDetailModalClose = this.kitSetDetailModalClose.bind(this);
    this.setSelectedOptionBookingType =
      this.setSelectedOptionBookingType.bind(this);
    this.setSelectedOptionSurgeon = this.setSelectedOptionSurgeon.bind(this);
    this.addSurgeonModal = this.addSurgeonModal.bind(this);
    this.addNewSurgeonModalClose = this.addNewSurgeonModalClose.bind(this);
    this.setSugestedSelectedOptionKit =
      this.setSugestedSelectedOptionKit.bind(this);
    this.handlePoNumber = this.handlePoNumber.bind(this);
    this.setSelectedOptionBilateralCase =
      this.setSelectedOptionBilateralCase.bind(this);
  }

  isEditable() {
    this.setState({
      isEditable: true,
    });
  }

  handlePoNumber(val) {
    this.setState({
      editablePoNumber: val,
      // changesMade: true,
    });
  }

  checkDateModalClose() {
    this.setState({
      checkDateFlag: false,
    });
  }

  updatePONumber(bookingId) {
    repsBookingService
      .updatePONumberWithSendMail({
        id: bookingId,
        po_number: this.state.editablePoNumber,
        po_check: false,
      })
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            isEditable: false,
            kitWithKitSet: [],
          });
          this.getSingleBooking(this.props.match.params.id);
          toast.success("PO Number updated successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      })
      .catch((error) => {
        this.setState({
          error: error.common[0],
        });
        toast.error("Error updating PO Number", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      });
  }

  getSingleBooking(id) {
    this.props.dispatch(repsBookingActions.getById(id));
  }

  getList() {
    this.props.dispatch(
      repsBookingActions.getRepsList({ search: this.state.searchReps })
    );

    this.props.dispatch(
      operationActions.getOperationsList({
        search: this.state.searchOps,
      })
    );
    this.props.dispatch(kitActions.kitListDropdownForReps({ search: "" }));
  }

  getHospitalAddressList() {
    //console.log("this.state.selectedHospital", this.state.selectedHospital);
    if (this.state.selectedHospital?.length > 0) {
      repsBookingService
        .getHospitalDetailsById(this.state.selectedHospital[0].value)
        .then((response) => {
          this.showAddressList(response.data);
        });
    }
  }

  setBookingData(data) {
    //console.log("defaultHospitalqqq", this.state.hospitals);
    var defaultReps = this.state.reps.find(
      (val) => val.value === data.assigned_rep
    );
    var defaultAdditionalReps = [];
    data.additional_reps.map((repValue) => {
      defaultAdditionalReps.push(
        this.state.additionalReps.find((val) => val.value === repValue)
      );
    });

    var surgeonData = [];
    data?.bookingSurgeonId.map((surVal) => {
      surgeonData.push(surVal.surgeonId[0]._id);
    });

    const { updateBookingInfo } = this.state;
    updateBookingInfo["id"] = data["_id"];
    updateBookingInfo["operation_name"] = data["operation_name"];
    updateBookingInfo["hospitalId"] = data["hospitalId"];
    updateBookingInfo["hospital_address"] = data["hospital_address"];
    updateBookingInfo["hospital_department"] = data["hospital_department"];
    updateBookingInfo["operation_date_slot"] = data["operation_date_slot"];
    updateBookingInfo["po_number"] = data["po_number"];
    updateBookingInfo["shipping_charge"] = data["shipping_charge"];
    updateBookingInfo["delivery_date_slot"] = data["delivery_date_slot"];
    updateBookingInfo["assigned_rep"] = data["assigned_rep"];
    updateBookingInfo["delivery_date"] = new Date(data["delivery_date"]);
    updateBookingInfo["operation_date"] = new Date(data["operation_date"]);
    updateBookingInfo["collection_date"] = new Date(data["collection_date"]);
    updateBookingInfo["additional_notes"] = data["additional_notes"];
    updateBookingInfo["booking_type"] = data["booking_type"];
    updateBookingInfo["surgeonData"] = surgeonData;
    updateBookingInfo["other_opration"] = data["other_opration"];
    updateBookingInfo["po_check"] = data["po_check"];
    updateBookingInfo["pickup_location_checkbox"] =
      data["pickup_location_checkbox"];
    updateBookingInfo["pickup_location_text"] = data["pickup_location_text"];
    //updateBookingInfo["bilateral_case"] = data["bilateral_case"] == "Yes" ? true : false;
    updateBookingInfo["bilateral_case"] = data["bilateral_case"];

    var tempAdditionalReps = [];
    data.additional_reps.map((item) => {
      tempAdditionalReps.push({ id: item });
    });
    updateBookingInfo["additional_reps"] = tempAdditionalReps;

    //additional_reps
    this.setState(
      {
        updateBookingInfo,
        bookingInfo: data,
        selectedOperation: {
          value: data.operation_name,
          label: data.operation_name,
        },
        selectedOperationSlots: {
          value: data.operation_date_slot,
          label: data.operation_date_slot,
        },
        selectedDeliverySlot: {
          value: data.delivery_date_slot,
          label: data.delivery_date_slot,
        },
        selectedRep: defaultReps,
        selectedAdditionalRep: defaultAdditionalReps,
        selectedBookingType: [
          { value: data.booking_type, label: data.booking_type },
        ],
        selectedHospital: [{ value: data.hospitalId, label: "" }],
        selectedBilateralCase: [
          { value: data.bilateral_case, label: data.bilateral_case },
        ],

        editablePoNumber: data["po_number"],
      },
      () => {
        this.showHospitalsList();
        this.getHospitalAddressList();
        this.setSurgeonList();
      }
    );

    console.log("booking ", data);

    data.bookingKitId.map((item) => {
      this.props.dispatch(
        repsBookingActions.getKitSetByKit({ kitId: item.kitId })
      );
    });
  }

  showHospitalsList() {
    repsBookingService
      .getHospitalsList({ search: this.state.searchHospital })
      .then((response) => {
        var hospitalsList = response.data;

        var tempHospitals = [];
        hospitalsList.map((item) => {
          var option = { value: item._id, label: item.name };
          tempHospitals.push(option);
        });
        this.setState(
          {
            hospitalDetails: hospitalsList,
            hospitals: tempHospitals,
          },
          () => {
            this.setHospitalEdit();
          }
        );
      });
  }

  setHospitalEdit() {
    const { updateBookingInfo } = this.state;
    var defaultHospital = this.state.hospitals.find(
      (val) => val.value === updateBookingInfo.hospitalId
    );
    this.setState({
      updateBookingInfo,
      previousSelectedHospital: [defaultHospital],
      selectedHospital: [defaultHospital],
    });
  }

  setSelectedOptionSurgeon(e) {
    var setSelectedOptionSurgeon = [{ value: e.value, label: e.label }];
    this.setState({
      selectedSurgeon: setSelectedOptionSurgeon,
      updateBookingInfo: {
        ...this.state.updateBookingInfo,
        surgeonData: [e.value],
      },
      changesMade: true,
      surgeonNoteDetails: e?.notes,
    });
  }

  showOperationsList(operationsList) {
    var tempOperations = [];
    operationsList.map((item) => {
      var option = { value: item.name, label: item.name };
      tempOperations.push(option);
    });
    //tempOperations.push( {value: "Other", label: "Other"});
    this.setState({
      operationsList: tempOperations,
    });
  }

  setSurgeonEdit() {
    const { updateBookingInfo } = this.state;
    var defaultSurgeon = [];
    var defaultSurgeonNotes = "";
    //console.log("11122222", updateBookingInfo.surgeonData);
    if (updateBookingInfo.surgeonData.length > 0) {
      // console.log("ssssssss", updateBookingInfo.surgeonData[0]);
      // console.log("this.state.surgeonList", this.state.surgeonList);
      this.state.surgeonList &&
        this.state.surgeonList
          .filter((val) => val.value === updateBookingInfo.surgeonData[0])
          .map((surValue) => {
            defaultSurgeon.push({
              value: surValue.value,
              label: surValue.label,
              notes: surValue?.notes,
            });
            defaultSurgeonNotes = surValue?.notes;
          });
      console.log("defaultSurgeon", defaultSurgeon);
    }

    this.setState(
      {
        selectedSurgeon: defaultSurgeon,
        surgeonNoteDetails: defaultSurgeonNotes,
      },
      () => {
        console.log(this.state.surgeonList);
        console.log(this.state.selectedSurgeon);
      }
    );
  }

  setSurgeonList() {
    surgeonService.getSurgeonsList({ search: "" }).then((response) => {
      var surgeonList = [];
      response.data.map((item) => {
        var option = {
          value: item._id,
          label: item.first_name + " " + item.last_name,
          notes: item?.notes ? item?.notes : "",
        };
        surgeonList.push(option);
      });

      this.setState(
        {
          surgeonList: surgeonList,
        },
        () => {
          this.setSurgeonEdit();
        }
      );
    });
  }

  showAddressList(address) {
    var hospitalAddress = [];
    var hospitalDepartment = [];
    address.addresses.map((item) => {
      if (this.state.bookingInfo.hospital_address === item.name) {
        hospitalAddress.push({
          _id: item._id,
          name: item.name,
          checked: true,
        });
      } else {
        hospitalAddress.push({
          _id: item._id,
          name: item.name,
          checked: false,
        });
      }
    });

    address.departments.map((item) => {
      if (this.state.bookingInfo.hospital_department === item.name) {
        hospitalDepartment.push({
          _id: item._id,
          name: item.name,
          checked: true,
        });
      } else {
        hospitalDepartment.push({
          _id: item._id,
          name: item.name,
          checked: false,
        });
      }
    });

    this.setState(
      {
        hospitalAddress: hospitalAddress,
        hospitalDepartment: hospitalDepartment,
        hospitalContactPerson: address.contact_person_name,
        hospitalContactPersonEmail: address.email,
        hospitalContactPersonPhone: address.contact_person_phone_number,
        hospital_hire_charge: address.default_hire_charge,
      },
      () => {
        console.log(this.state.hospitalAddress);
      }
    );
  }

  showRepsList(repsList) {
    var tempRepsList = [];
    repsList.map((item) => {
      var option = {
        value: item._id,
        label: item.first_name + " " + item.last_name,
      };
      tempRepsList.push(option);
    });
    this.setState({
      repsDetails: repsList,
      reps: tempRepsList,
      additionalReps: tempRepsList,
    });
  }

  setSelectedOptionOperation(e) {
    var selectedOperation = [{ value: e.value, label: e.label }];
    if (e.label != "Other") {
      this.setState({
        selectedOperation: selectedOperation,
        updateBookingInfo: {
          ...this.state.updateBookingInfo,
          operation_name: e.label,
          other_opration: "",
        },
        changesMade: true,
      });
    }
    if (e.label == "Other") {
      this.setState({
        selectedOperation: selectedOperation,
        updateBookingInfo: {
          ...this.state.updateBookingInfo,
          operation_name: e.label,
        },
      });
    }
  }

  setSelectedOptionBookingType(e) {
    var selectedBookingType = [{ value: e.value, label: e.label }];

    this.setState({
      selectedBookingType: selectedBookingType,
      updateBookingInfo: {
        ...this.state.updateBookingInfo,
        booking_type: e.value,
      },
      changesMade: true,
    });
  }

  setSelectedOptionHospital(e) {
    var selectedHospital = [{ value: e.value, label: e.label }];
    this.setState(
      {
        selectedHospital: selectedHospital,
        updateBookingInfo: {
          ...this.state.updateBookingInfo,
          hospitalId: e.value,
        },
        changesMade: true,
      },
      () => {
        this.getHospitalAddressList();
      }
    );
  }

  setSelectedOptionRep(e) {
    var selectedRep = [{ value: e.value, label: e.label }];
    this.setState({
      selectedRep: selectedRep,
      updateBookingInfo: {
        ...this.state.updateBookingInfo,
        assigned_rep: e.value,
      },
      changesMade: true,
    });
  }

  setSelectedOptionAdditionalRep = (selectedAdditionalRep) => {
    var tempAdditionalReps = [];
    selectedAdditionalRep.map((item) => {
      tempAdditionalReps.push({ id: item.value });
    });
    this.setState(
      {
        selectedAdditionalRep: selectedAdditionalRep,
        updateBookingInfo: {
          ...this.state.updateBookingInfo,
          additional_reps: tempAdditionalReps,
        },
        changesMade: true,
      },
      () => {
        console.log(this.state.updateBookingInfo.additional_reps);
      }
    );
  };

  setSelectedOptionKit(e) {
    var selectedKit = [{ value: e.value, label: e.label }];
    this.setState({
      selectedKit: selectedKit,
    });
  }

  setSugestedSelectedOptionKit(e) {
    var selectedKit = [{ value: e.value, label: e.label }];
    this.setState(
      {
        selectedKit: selectedKit,
      },
      () => {
        this.handleAddKit();
      }
    );
  }

  showKitsList(kitList) {
    var tempKitList = [];
    kitList.map((item) => {
      var option = { value: item._id, label: item.name };
      tempKitList.push(option);
    });
    this.setState({
      kits: tempKitList,
      kitListFlag: 1,
    });
  }

  handleAddKit() {
    if (this.state.selectedKit.length > 0) {
      this.props.dispatch(
        repsBookingActions.getKitSetByKit({
          kitId: this.state.selectedKit[0].value,
        })
      );
    }
  }

  showKitsSetList(data) {
    const {
      kitWithKitSet,
      updateBookingInfo,
      bookingInfo,
      hospital_hire_charge,
    } = this.state;
    let newKitWithKitSet = [];
    var subKitSet = [];
    var lnt = 0;
    var hire_charge = "";

    data[0]["kitsets"].map((kset) => {
      (hire_charge = hospital_hire_charge ? hospital_hire_charge : 300),
        (lnt = data[0].bookingKitSets.filter(
          (rs) => rs.setId === kset._id && rs.bookingId == updateBookingInfo.id
        ).length);
      if (lnt > 0) {
        data[0].bookingKitSets
          .filter(
            (rs) =>
              rs.setId === kset._id && rs.bookingId == updateBookingInfo.id
          )
          .map((result) => {
            hire_charge = result.hire_charge;
          });
      }
      subKitSet.push({
        bookingKitSets: data[0]["bookingKitSets"],
        set_name: kset.set_name,
        _id: kset._id,
        hire_charge: hire_charge,
        added: lnt,
      });
    });

    var kitquantity = "";
    var kithire_charge = hospital_hire_charge ? hospital_hire_charge : 300;

    bookingInfo.bookingKitId
      .filter((item) => item.kitId == data[0]._id)
      .map((maini) => {
        kithire_charge = maini.hire_charge;
        kitquantity = maini.quantity;
      });

    newKitWithKitSet.push({
      kitDocument: data[0].kitDocument,
      name: data[0].name,
      _id: data[0]._id,
      hire_charge: kithire_charge,
      quantity: kitquantity,
      sets: subKitSet,
    });

    this.setState({
      suggestedKits: data[0].suggestedKits,
    });

    var kits = this.state.kits;
    var slectedVal = data[0]._id;
    kits = kits.filter((rs) => rs.value !== slectedVal);

    let newArr = [...newKitWithKitSet, ...kitWithKitSet];

    this.setState({
      kitWithKitSet: newArr,
      selectedKit: [],
      kits: kits,
    });
  }

  handelRemovKit(kiIndex) {
    const { kitWithKitSet, kits } = this.state;
    const kitValue = kitWithKitSet[kiIndex]._id;
    const kitLable = kitWithKitSet[kiIndex].name;
    kitWithKitSet.splice(kiIndex, 1);

    kits.push({ value: kitValue, label: kitLable });

    this.setState({
      kitWithKitSet,
      kits,
    });
  }

  handelAddRemovSet(kiIndex, setIndex, flag) {
    const { kitWithKitSet } = this.state;
    kitWithKitSet[kiIndex].sets[setIndex].added = flag;

    this.setState({
      kitWithKitSet,
    });
  }

  handleHireCharge(kiIndex, setIndex, val) {
    const { kitWithKitSet } = this.state;
    if (val != "" && val < 0) {
      val = "";
    }
    kitWithKitSet[kiIndex].sets[setIndex].hire_charge = val;

    this.setState({
      kitWithKitSet,
      changesMade: true,
    });
  }

  handleKitHireCharge(index, val) {
    const { kitWithKitSet } = this.state;

    if (val != "" && val < 0) {
      val = "";
    }

    kitWithKitSet[index].hire_charge = val;

    this.setState({
      kitWithKitSet,
    });
  }

  handleKitQuantity(index, val) {
    const { kitWithKitSet } = this.state;
    if (val != "" && val < 0) {
      val = "";
    }

    kitWithKitSet[index].quantity = val;

    this.setState({
      kitWithKitSet,
      quantityError: false,
      changesMade: true,
    });
  }

  handlePickupCheckbox(value) {
    const { updateBookingInfo } = this.state;
    updateBookingInfo["pickup_location_checkbox"] = value;
    this.setState({
      updateBookingInfo,
      changesMade: true,
    });
  }

  handleUpdateBookingInfo= async (name, value) => {
    const { updateBookingInfo } = this.state;
 
 if (name === "delivery_date") {
  let selectedDate = new Date(value);
  let today = new Date();
  today.setHours(0, 0, 0, 0); 
 
  const holidays = await bookingService
    .getHolidayList({ page: "", limit: "" })
    .then((response) =>
      response?.data?.map((holiday) => new Date(holiday.date))
    )
    .catch((error) => {
      console.error("Error fetching holiday list: ", error);
      return [];
    });
 
  const isHolidayOrWeekend = (date) => {
    const day = date.getDay();
    return day === 6 || day === 0 || holidays.some(
      (holiday) => holiday.toDateString() === date.toDateString()
    );
  };
 
  const getNextWorkingDay = (date) => {
    let newDate = new Date(date);
    do {
      newDate.setDate(newDate.getDate() + 1);
    } while (isHolidayOrWeekend(newDate));
    return newDate;
  };
 
  const getPreviousWorkingDay = (date) => {
    let newDate = new Date(date);
    do {
      newDate.setDate(newDate.getDate() - 1);
    } while (isHolidayOrWeekend(newDate));
    return newDate;
  };
 
  let nextWorkingDay = getNextWorkingDay(today);
  let previousWorkingDay = getPreviousWorkingDay(selectedDate);
 
  let isSelectedWeekendOrHoliday = isHolidayOrWeekend(selectedDate);
    // Get current time
    let currentHour = new Date().getHours();
    let currentMinutes = new Date().getMinutes();
    let isAfterCutoffTime = currentHour > 15 || (currentHour === 15 && currentMinutes >= 45);
  console.log("DEBUG:", {
    today,
    selectedDate,
    nextWorkingDay,
    previousWorkingDay,
    isSelectedWeekendOrHoliday
  });
 
  if (isAfterCutoffTime &&
    !isSelectedWeekendOrHoliday &&
    nextWorkingDay.toDateString() === previousWorkingDay.toDateString()) {
    this.setState({ showCutOffWarning: true });
  } else {
    this.setState({ showCutOffWarning: false });
  }
 }
 

    if (
      name == "delivery_date" ||
      name == "collection_date" ||
      name == "operation_date"
    ) {
      let date = dateFormat(value, "yyyy-mm-dd");
      bookingService
        .checkHolidayDate({ date: date })
        .then((response) => {
          console.log("checkholiday", response);
          if (response.data && response.data.length !== 0) {
            if (name === "delivery_date") {
              this.setState((prevState) => ({
                deliveryDateError: "Delivery Date falls on a holiday.",
                operationDateError: prevState.operationDateError,
                collectionDateError: prevState.collectionDateError,
              }));
            } else if (name === "collection_date") {
              this.setState((prevState) => ({
                deliveryDateError: prevState.deliveryDateError,
                collectionDateError: "Collection Date falls on a holiday.",
                operationDateError: prevState.operationDateError,
              }));
            } else if (name === "operation_date") {
              this.setState((prevState) => ({
                deliveryDateError: prevState.deliveryDateError,
                collectionDateError: prevState.collectionDateError,
                operationDateError: "Operation Date falls on a holiday.",
              }));
            }
          } else {
            this.setState((prevState) => ({
              deliveryDateError:
                name === "delivery_date" ? "" : prevState.deliveryDateError,
              operationDateError:
                name === "operation_date" ? "" : prevState.operationDateError,
              collectionDateError:
                name === "collection_date" ? "" : prevState.collectionDateError,
            }));
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }

    if (name == "shipping_charge" && value != "" && value < 0) {
      value = "";
    }
    updateBookingInfo[name] = value;

    if (name == "po_check" && value == true) {
      updateBookingInfo["po_number"] = "";
    }
    if (name == "po_number" && value == "") {
      updateBookingInfo["po_check"] = true;
    } else if (name == "po_number" && value != "") {
      updateBookingInfo["po_check"] = false;
    }

    this.setState(
      {
        updateBookingInfo,
        changesMade: true,
      },
      () => {
        console.log(this.state.updateBookingInfo);
      }
    );
  };

  handleBookingAddress(value, checked, ind) {
    const { updateBookingInfo, hospitalAddress } = this.state;
    hospitalAddress.map((item) => {
      item.checked = false;
    });
    hospitalAddress[ind].checked = true;
    this.setState({
      hospitalAddress,
      changesMade: true,
    });

    updateBookingInfo["hospital_address"] = value;
    this.setState({
      updateBookingInfo,
    });
  }

  handleBookingDepartment(value, checked, ind) {
    const { updateBookingInfo, hospitalDepartment } = this.state;
    hospitalDepartment.map((item) => {
      item.checked = false;
    });
    hospitalDepartment[ind].checked = true;
    this.setState({
      hospitalDepartment,
      changesMade: true,
    });

    updateBookingInfo["hospital_department"] = value;
    this.setState({
      updateBookingInfo,
    });
  }

  setSelectedOptionDeliverySlot(e) {
    var selectedDeliverySlot = [{ value: e.value, label: e.label }];
    this.setState({
      selectedDeliverySlot: selectedDeliverySlot,
      updateBookingInfo: {
        ...this.state.updateBookingInfo,
        delivery_date_slot: e.value,
      },
      changesMade: true,
    });
  }

  setSelectedOptionOperationSlots(e) {
    var selectedOperationSlots = [{ value: e.value, label: e.label }];
    this.setState({
      selectedOperationSlots: selectedOperationSlots,
      updateBookingInfo: {
        ...this.state.updateBookingInfo,
        operation_date_slot: e.value,
      },
      changesMade: true,
    });
  }

  calculateReturnDate = async (collectionDate) => {
    let return_date = new Date(collectionDate);
    return_date.setDate(return_date.getDate() + 1);
    const holidays = await bookingService
      .getHolidayList({ page: "", limit: "" })
      .then((response) =>
        response?.data?.map((holiday) => new Date(holiday.date))
      )
      .catch((error) => {
        console.error("Error fetching holiday list: ", error);
        return [];
      });

    while (this.isHolidayOrWeekend(return_date, holidays)) {
      return_date.setDate(return_date.getDate() + 1);
    }
    this.setState((prevState) => ({
      updateBookingInfo: {
        ...prevState.updateBookingInfo,
        return_date: dateFormat(return_date, "yyyy-mm-dd"),
      },
    }));

    return return_date;
  };

  isHolidayOrWeekend = (date, holidays) => {
    const day = date.getDay();
    const isWeekend = day === 6 || day === 0;
    const isHoliday = holidays?.some(
      (holiday) =>
        holiday.getFullYear() === date.getFullYear() &&
        holiday.getMonth() === date.getMonth() &&
        holiday.getDate() === date.getDate()
    );
    return isWeekend || isHoliday;
  };

  handleSubmit = async (flag) => {
    if (this.state.changesMade) {
      console.log("proceed", flag);
      const { updateBookingInfo, kitWithKitSet } = this.state;

      const sameDates =
        updateBookingInfo.delivery_date &&
        updateBookingInfo.operation_date &&
        updateBookingInfo.delivery_date_slot !== "Same Day" &&
        updateBookingInfo.delivery_date.getTime() ===
          updateBookingInfo.operation_date.getTime();

      console.log("sameDates:", sameDates);

      if (sameDates && !this.state.proceed) {
        this.setState({
          sameDatesModal: true,
          checkDateFlag: true,
        });
        return;
      }

      this.setState({
        sameDatesModal: false,
        checkDateFlag: false,
      });

      var kitError = 0;
      var kitData = [];
      var kitSetData = [];
      let kitSetFlag = 0;
      var kitSetErrorFlag = 0;
      this.setState({ quantityError: true, isLoading: true });

      kitWithKitSet.map((item) => {
        kitData.push({
          id: item._id,
          hire_charge: item.hire_charge,
          quantity: item.quantity,
        });
        kitSetFlag = 1;
        kitError = 1;
        item.sets
          .filter((rs) => rs.added === 1)
          .map((sub) => {
            kitSetData.push({
              id: sub._id,
              hire_charge: sub.hire_charge,
              kitId: item._id,
            });
          });
      });

      updateBookingInfo["return_date"] = "";

      if (updateBookingInfo["collection_date"] !== "") {
        let return_date = new Date(updateBookingInfo["collection_date"]);
        return_date = await this.calculateReturnDate(return_date);
        // return_date = return_date.setDate(return_date.getDate() + 1);
        updateBookingInfo["return_date"] = dateFormat(
          return_date,
          "yyyy-mm-dd"
        );
      }

      if (updateBookingInfo["booking_type"] === "consignment") {
        updateBookingInfo["operation_date"] =
          updateBookingInfo["collection_date"];
      }

      updateBookingInfo["kitData"] = kitData;
      updateBookingInfo["kitSetData"] = kitSetData;

      console.log(kitSetFlag);

      if (
        updateBookingInfo["hospitalId"] !== "" &&
        updateBookingInfo["po_number"] !== "" &&
        updateBookingInfo["delivery_date"] !== "" &&
        updateBookingInfo["operation_date"] !== "" &&
        updateBookingInfo["collection_date"] !== "" &&
        updateBookingInfo["kitData"].length !== 0 &&
        updateBookingInfo["kitSetData"].length !== 0 &&
        this.state.changesMade !== true
      ) {
        kitSetErrorFlag = 1;
        this.setState({
          kitSetErrorFlag: 0,
        });
      } else {
        kitSetErrorFlag = 0;
        if (kitError === 1) {
          kitSetErrorFlag = 1;
          this.setState({
            kitSetErrorFlag: 0,
          });
        } else {
          this.setState({
            isLoading: false,
            kitSetErrorFlag: 1,
          });
        }
      }

      updateBookingInfo["log_status"] = "pending";

      updateBookingInfo["log_by_id"] = this.state.user.data._id;

      console.log("updateBookingInfo", updateBookingInfo);
      if (kitSetErrorFlag === 1) {
        var datass = {
          additional_notes: updateBookingInfo["additional_notes"],
          additional_reps: updateBookingInfo["additional_reps"],
          assigned_rep: updateBookingInfo["assigned_rep"],
          booking_type: updateBookingInfo["booking_type"],
          collection_date: dateFormat(
            updateBookingInfo["collection_date"],
            "yyyy-mm-dd"
          ),
          delivery_date: dateFormat(
            updateBookingInfo["delivery_date"],
            "yyyy-mm-dd"
          ),
          delivery_date_slot: updateBookingInfo["delivery_date_slot"],
          hospitalId: updateBookingInfo["hospitalId"],
          hospital_address: updateBookingInfo["hospital_address"],
          hospital_department: updateBookingInfo["hospital_department"],
          id: updateBookingInfo["id"],
          kitData: updateBookingInfo["kitData"],
          kitSetData: updateBookingInfo["kitSetData"],
          log_by_id: updateBookingInfo["log_by_id"],
          log_status: updateBookingInfo["log_status"],
          operation_date: dateFormat(
            updateBookingInfo["operation_date"],
            "yyyy-mm-dd"
          ),
          operation_date_slot: updateBookingInfo["operation_date_slot"],
          operation_name: updateBookingInfo["operation_name"],
          other_opration: updateBookingInfo["other_opration"],
          po_number: updateBookingInfo["po_number"],
          //"reject_status": updateBookingInfo["reject_status"],
          return_date: updateBookingInfo["return_date"],
          shipping_charge: updateBookingInfo["shipping_charge"],
          status: updateBookingInfo["log_status"],
          surgeonData: updateBookingInfo["surgeonData"],
          po_check: updateBookingInfo["po_check"],
          pickup_location_checkbox:
            updateBookingInfo["pickup_location_checkbox"],
          pickup_location_text: updateBookingInfo["pickup_location_text"],
          bilateral_case: updateBookingInfo["bilateral_case"],
        };

        this.props.dispatch(repsBookingActions.update(datass));
      } else return;
    }
    this.setState({
      proceed: false,
    });
  };

  openModalAndSubmit = (flag) => {
    this.handleSubmit(flag);
  };

  kitSetDetailModalClose() {
    this.setState({
      kitSetDetailModal: false,
      kitSetData: [],
    });
  }

  showKitDetails(kitDetails) {
    if (this.state.kitDetailsClickFlag == 1) {
      this.setState({
        kitDetails: kitDetails,
        kitSetDetailModal: true,
        kitDetailsClickFlag: 0,
      });
    }
  }

  handleKitDetails(id) {
    this.setState({
      kitDetailsClickFlag: 1,
    });

    this.props.dispatch(setActions.getById(id));
  }

  checkAvalability(bookingKitSets, setId) {
    const { updateBookingInfo } = this.state;
    console.log(setId);
    var htm = [];

    var currentDate = updateBookingInfo.delivery_date;
    var endDate = updateBookingInfo.collection_date;
    var falgForHtm = 0;
    var date = "";
    var separator = "-";
    var fulldate = "";
    if (currentDate != "" && endDate != "") {
      currentDate = new Date(currentDate);
      endDate = new Date(endDate);
      var dates = [];
      while (currentDate <= endDate) {
        date = `${currentDate.getFullYear()}${separator}${
          currentDate.getMonth() + 1 < 10
            ? `0${currentDate.getMonth() + 1}`
            : `${currentDate.getMonth() + 1}`
        }${separator}${
          currentDate.getDate() < 10
            ? `0${currentDate.getDate()}`
            : `${currentDate.getDate()}`
        }`;

        dates.push({ fulldate: date });
        currentDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate() + 1 // Will increase month if over range
        );
      }

      if (bookingKitSets.length == 0) {
        htm.push(<span className="capsule green-dim">Available</span>);
      } else {
        dates &&
          dates.map((diffdate) => {
            fulldate = diffdate.fulldate;
            bookingKitSets
              .filter((rs) => rs.setId == setId)
              .map((bookingSt) => {
                if (
                  bookingSt.bookings &&
                  bookingSt.bookings.filter(
                    (rs) =>
                      rs._id != updateBookingInfo.id &&
                      rs.delivery_date <= fulldate &&
                      rs.collection_date >= fulldate
                  ).length
                ) {
                  falgForHtm += 1;
                }
              });
          });

        if (falgForHtm > 0) {
          htm.push(<span className="capsule red-dim">Not Available</span>);
        }

        if (falgForHtm == 0) {
          htm.push(<span className="capsule green-dim">Available</span>);
        }
      }
    } else {
      htm.push(<span className="capsule green-dim">Available</span>);
    }

    return htm;
  }

  addSurgeonModal() {
    this.setState({
      addSurgeonModal: true,
    });
  }

  addNewSurgeonModalClose() {
    this.setState({ addSurgeonModal: false });
    this.setSurgeonList();
  }

  addHospitalModal() {
    this.setState({
      addNewHospitalModalProp: true,
    });
  }

  addNewHospitalModalClose() {
    this.setState({ addNewHospitalModalProp: false });
    this.props.dispatch(
      repsBookingActions.getHospitalsList({
        search: this.state.searchHospital,
      })
    );
  }

  editHospitalModalOpen(id) {
    this.setState({
      editHospitalId: id,
      editHospitalModal: !this.state.editModal,
    });
  }

  editHospitalModalClose() {
    this.setState({
      editHospitalModal: false,
      editHospitalId: 0,
    });
  }

  refreshHospitalList() {
    if (this.state.selectedHospital.length > 0) {
      this.showHospitalsList();
      this.getHospitalAddressList();
    }
  }

  componentDidMount() {
    this.getList();
    const timer = setTimeout(() => {
      this.getSingleBooking(this.props.match.params.id);
    }, 1500);
    return () => clearTimeout(timer);
  }

  setSelectedOptionBilateralCase(e) {
    var selectedBilateralCase = [{ value: e.value, label: e.label }];
    this.setState({
      selectedBilateralCase: selectedBilateralCase,
      updateBookingInfo: {
        ...this.state.updateBookingInfo,
        bilateral_case: e.value,
      },
      changesMade: true,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log(nextProps);

    if (nextProps.bookingInfo !== undefined) {
      this.setBookingData(nextProps.bookingInfo);
    }

    if (nextProps.hospitalDetails !== undefined) {
      this.showAddressList(nextProps.hospitalDetails);
    }

    if (nextProps.repsList !== undefined) {
      this.showRepsList(nextProps.repsList);
    }

    if (
      nextProps.kitListDropdown !== undefined &&
      this.state.kitListFlag == 0
    ) {
      this.showKitsList(nextProps.kitListDropdown);
    }

    if (nextProps.kitSetList !== undefined) {
      this.showKitsSetList(nextProps.kitSetList);
    }

    if (nextProps.editSet == true) {
      this.showKitDetails(nextProps.set);
    }

    if (nextProps.opLists !== undefined) {
      this.showOperationsList(nextProps.opLists);
    }

    if (nextProps.redirect !== undefined) {
      this.setState({ isLoading: false });
    }
    if (
      nextProps.sameDateList !== undefined &&
      nextProps?.sameDateList?.length
    ) {
      this.setState({
        sameDatesInfo: nextProps.sameDateList,
        sameDatesModal: true,
        isLoading: false,
      });
    }
  }

  render() {
    const { redirect, error } = this.props;
    const { changesMade } = this.state;
    if (
      redirect &&
      this.state.updateBookingInfo.booking_type != "consignment"
    ) {
      return <Redirect to="/reps/open-booking" />;
    }
    if (
      redirect &&
      this.state.updateBookingInfo.booking_type == "consignment"
    ) {
      return <Redirect to="/reps/consignment-booking" />;
    }
    if (!this.state.user) {
      return <Redirect to="/" />;
    }
    if (this.state.user.data.user_role !== "reps") {
      return <Redirect to="/" />;
    }

    const { surgeonNoteDetails, isLoading } = this.state;

    return (
      <div>
        <Navbar activePage="bookings" />
        <div className="container">
          <h1 className="date-scroll pt-4">
            <a onClick={() => history.goBack()}>
              <i className="fa fa-long-arrow-left smbtn"></i>{" "}
              <strong>Back</strong>
            </a>
          </h1>
          <div className="row pt-4 mobile_nocard">
            <div className="col-lg-8">
              <div className="d-card">
                <h4>Basic Details</h4>
                <div className="form-group">
                  <label>
                    Hospital Name <span className="text-danger">*</span>
                  </label>
                  <span className="pull-right">
                    <a
                      href="javascript:;"
                      className="btn-link"
                      onClick={() => this.addHospitalModal()}
                    >
                      + Add New Hospital
                    </a>
                  </span>

                  <div className="position_up" style={{ zIndex: 8 }}>
                    <Select
                      value={this.state.selectedHospital}
                      onChange={this.setSelectedOptionHospital}
                      options={this.state.hospitals}
                      maxMenuHeight={190}
                    />
                  </div>
                </div>
                {this.state.selectedHospital.length > 0 ? (
                  <div className="row position-relative">
                    <div className="edit_fld">
                      <div className="">
                        {" "}
                        <i
                          onClick={() =>
                            this.editHospitalModalOpen(
                              this.state.selectedHospital[0].value
                            )
                          }
                          className="fa fa-pencil"
                        ></i>
                      </div>
                    </div>
                    <div className="info col-md-6">
                      <h6>Address</h6>
                      <hr />
                      {this.state.hospitalAddress.map((item, ind) => (
                        <div
                          className="info-left"
                          style={{
                            marginLeft: "1.25rem",
                          }}
                          key={item._id}
                        >
                          <Input
                            type="radio"
                            id={item._id}
                            name="hospitalAddress"
                            value={item.name}
                            checked={item.checked == true}
                            onChange={(e) =>
                              this.handleBookingAddress(
                                e.target.value,
                                e.target.checked,
                                ind,
                                item
                              )
                            }
                          />
                          <strong> {this.state.hospitalContactPerson}</strong>
                          <div>
                            <small>Location: {item.name}</small>
                          </div>
                          <div>
                            <small>
                              Email: {this.state.hospitalContactPersonEmail}
                            </small>
                          </div>
                          <div>
                            <small>
                              Phone: {this.state.hospitalContactPersonPhone}
                            </small>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="info col-md-6">
                      <h6>Department</h6>
                      <hr />
                      {this.state.hospitalDepartment.map((item, ind) => (
                        <div
                          className="info-left"
                          style={{
                            marginLeft: "1.25rem",
                          }}
                          key={item._id}
                        >
                          <Input
                            type="radio"
                            id={item._id}
                            name="hospitalDepartment"
                            value={item.name}
                            checked={item.checked == true}
                            onChange={(e) =>
                              this.handleBookingDepartment(
                                e.target.value,
                                e.target.checked,
                                ind,
                                item
                              )
                            }
                          />
                          <div>
                            <small>{item.name}</small>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="col-md-12">
                      <div className="form-group mt-3">
                        <span className="">
                          <Input
                            type="checkbox"
                            className="ml-0 form-check-input"
                            value={
                              this.state.updateBookingInfo
                                .pickup_location_checkbox
                            }
                            checked={
                              this.state.updateBookingInfo
                                .pickup_location_checkbox
                            }
                            onChange={(e) =>
                              this.handlePickupCheckbox(e.target.checked)
                            }
                          />
                        </span>
                        <label className="ml-4 ">
                          Is Pickup Location And Contact Details Different
                        </label>
                        {this.state.updateBookingInfo
                          .pickup_location_checkbox ? (
                          <Input
                            type="text"
                            className="form-control"
                            value={
                              this.state.updateBookingInfo.pickup_location_text
                            }
                            onChange={(e) =>
                              this.handleUpdateBookingInfo(
                                "pickup_location_text",
                                e.target.value
                              )
                            }
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div className="form-group">
                  <label>
                    Surgeon Name <span className="text-danger">*</span>
                  </label>
                  <span className="pull-right">
                    <a
                      href="javascript:;"
                      className="btn-link"
                      onClick={() => this.addSurgeonModal()}
                    >
                      + Add New Surgeon
                    </a>
                  </span>

                  <div className="info position_up" style={{ zIndex: 7 }}>
                    <Select
                      value={this.state.selectedSurgeon}
                      onChange={this.setSelectedOptionSurgeon}
                      options={this.state.surgeonList}
                      maxMenuHeight={190}
                    />
                    {error ? (
                      <span className="text-danger">
                        {error.surgeonData ? error.surgeonData : ""}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>

                  {surgeonNoteDetails != "" ? (
                    <div className="info-left" style={{ marginTop: "0.75rem" }}>
                      <div>
                        <strong>Note : </strong>{" "}
                        <small>{surgeonNoteDetails}</small>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="form-group">
                  <label>
                    Operation Name <span className="text-danger">*</span>
                  </label>
                  <div className="position_up" style={{ zIndex: 4 }}>
                    <Select
                      value={this.state.selectedOperation}
                      onChange={this.setSelectedOptionOperation}
                      options={this.state.operationsList}
                      maxMenuHeight={190}
                    />
                  </div>
                </div>

                {this.state.updateBookingInfo.operation_name == "Other" && (
                  <div className="form-group">
                    <label>
                      Other Operation <span className="text-danger">*</span>
                    </label>
                    <Input
                      type="text"
                      className="form-control"
                      value={this.state.updateBookingInfo.other_opration}
                      onChange={(e) =>
                        this.handleUpdateBookingInfo(
                          "other_opration",
                          e.target.value
                        )
                      }
                    />
                    {error ? (
                      <span className="text-danger">
                        {error.other_opration ? error.other_opration : ""}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                )}

                <div className="form-group">
                  <label>
                    Bilateral Case <span className="text-danger">*</span>
                  </label>
                  <div className=" info position_up" style={{ zIndex: 3 }}>
                    <Select
                      value={this.state.selectedBilateralCase}
                      onChange={this.setSelectedOptionBilateralCase}
                      options={bilateralCaseList}
                      maxMenuHeight={190}
                    />
                    {error ? (
                      <span className="text-danger">
                        {error.bilateral_case ? error.bilateral_case : ""}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="form-group">
                  <span>
                    PO Number:{" "}
                    <strong>
                      {this.state.isEditable ? (
                        <React.Fragment>
                          <Input
                            type="text"
                            style={{
                              width: "fit-content",
                              display: "inline-block",
                            }}
                            value={this.state.editablePoNumber}
                            onChange={(e) =>
                              this.handlePoNumber(e.target.value)
                            }
                          />

                          <span className="small ml-2">
                            <a
                              href="javascript:;"
                              onClick={() =>
                                this.updatePONumber(
                                  this.state.updateBookingInfo.id
                                )
                              }
                            >
                              Save
                            </a>
                          </span>
                        </React.Fragment>
                      ) : (
                        this.state.updateBookingInfo.po_number
                      )}
                    </strong>{" "}
                  </span>
                  {this.state.updateBookingInfo.status != "cancel" &&
                    this.state.updateBookingInfo.status != "pending" &&
                    !this.state.isEditable && (
                      <a
                        href="javascript:"
                        onClick={() => this.isEditable()}
                        className="ml-1"
                      >
                        <i className="fa fa-pencil"></i>
                      </a>
                    )}
                </div>

                <div className="form-group">
                  <span className="mr-1">
                    <Input
                      type="checkbox"
                      className="ml-1"
                      value={this.state.updateBookingInfo.po_check}
                      checked={this.state.updateBookingInfo.po_check}
                      onChange={(e) =>
                        this.handleUpdateBookingInfo(
                          "po_check",
                          e.target.checked
                        )
                      }
                    />
                  </span>
                  <label className="ml-4">Awaiting PO</label>
                </div>
              </div>
              <div className="d-card my-4">
                <h4>Purchase/Hire Details</h4>
                <div className="row purchase_date">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        Delivery Date <span className="text-danger">*</span>
                      </label>
                      <div className="inputdata formDatess">
                        <DatePicker
                          selected={this.state.updateBookingInfo.delivery_date}
                          onChange={(date) =>
                            this.handleUpdateBookingInfo("delivery_date", date)
                          }
                          dateFormat="dd-MM-yyyy EEE"
                          className="small"
                          id="datepicker"
                          autoComplete="off"
                        />
                      </div>
                      {this.state.deliveryDateError && (
                        <div className="error-message">
                          <span className="text-danger">
                            {this.state.deliveryDateError}
                          </span>
                        </div>
                      )}
                      {error ? (
                        <span className="text-danger">
                          {error.delivery_date ? error.delivery_date : ""}
                        </span>
                      ) : (
                        ""
                      )}
                      {this.state.updateBookingInfo.delivery_date != "" &&
                        (new Date(
                          this.state.updateBookingInfo.delivery_date
                        ).getDay() == 6 ||
                          new Date(
                            this.state.updateBookingInfo.delivery_date
                          ).getDay() == 0) && (
                          <div>
                            <span className="text-danger">
                              Selected date is a weekend/holiday but you can
                              still make a booking
                            </span>
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label></label>
                      <div className="ml-0 position_up">
                        <Select
                          value={this.state.selectedDeliverySlot}
                          onChange={this.setSelectedOptionDeliverySlot}
                          options={deliverySlots}
                          className="small"
                        />
                      </div>
                    </div>
                  </div>

                  {this.state.updateBookingInfo.booking_type !=
                    "consignment" && (
                    <React.Fragment>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>
                            Operation Date{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <div className="inputdata formDatess">
                            <DatePicker
                              selected={
                                this.state.updateBookingInfo.operation_date
                              }
                              onChange={(date) =>
                                this.handleUpdateBookingInfo(
                                  "operation_date",
                                  date
                                )
                              }
                              dateFormat="dd-MM-yyyy EEE"
                              className="small"
                              id="datepicker2"
                              autoComplete="off"
                            />
                          </div>
                          {this.state.operationDateError && (
                            <div className="error-message">
                              <span className="text-danger">
                                {this.state.operationDateError}
                              </span>
                            </div>
                          )}
                          {error ? (
                            <span className="text-danger">
                              {error.operation_date ? error.operation_date : ""}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label></label>
                          <div className="mt-2">
                            <Select
                              value={this.state.selectedOperationSlots}
                              onChange={this.setSelectedOptionOperationSlots}
                              options={operationSlots}
                              className="small"
                            />
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  )}

                  <div className="col-md-12">
                    <div className="form-group">
                      <label>
                        Collection Date <span className="text-danger">*</span>
                      </label>
                      <div className="inputdata formDatess">
                        <DatePicker
                          selected={
                            this.state.updateBookingInfo.collection_date
                          }
                          onChange={(date) =>
                            this.handleUpdateBookingInfo(
                              "collection_date",
                              date
                            )
                          }
                          dateFormat="dd-MM-yyyy EEE"
                          className="small"
                          id="datepicker3"
                          autoComplete="off"
                        />
                      </div>
                      {this.state.collectionDateError && (
                        <div className="error-message">
                          <span className="text-danger">
                            {this.state.collectionDateError}
                          </span>
                        </div>
                      )}
                      {error ? (
                        <span className="text-danger">
                          {error.collection_date ? error.collection_date : ""}
                        </span>
                      ) : (
                        ""
                      )}
                      {this.state.updateBookingInfo.collection_date != "" &&
                        (new Date(
                          this.state.updateBookingInfo.collection_date
                        ).getDay() == 6 ||
                          new Date(
                            this.state.updateBookingInfo.collection_date
                          ).getDay() == 0) && (
                          <div>
                            <span className="text-danger mt-2">
                              Selected date is a weekend/holiday but you can
                              still make a booking
                            </span>
                          </div>
                        )}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12 col-12">
                    <label>
                      Select Kit <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-sm-9 col-8">
                    <div className="form-group mb-0">
                      <Select
                        value={this.state.selectedKit}
                        onChange={this.setSelectedOptionKit}
                        options={this.state.kits}
                        className="small"
                      />
                    </div>
                    {this.state.kitSetErrorFlag == 1 ? (
                      <span className="text-danger">
                        Please add at least one kit set
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-sm-3 col-4">
                    <Button
                      className="btn  btn-block"
                      onClick={this.handleAddKit}
                    >
                      <i className="fa fa-plus"></i> Add{" "}
                    </Button>
                  </div>
                </div>

                <div className="mt-4">
                  <table className="table mobile_full1">
                    <tbody>
                      {this.state.suggestedKits.length > 0 && (
                        <React.Fragment>
                          <tr>
                            <th colSpan="3" className="w-100 border-top-0">
                              <div className="mb-2">
                                <strong>Suggested Kits</strong>
                              </div>
                              {this.state.suggestedKits.map(
                                (sugKit, sugInd) => (
                                  <React.Fragment key={sugInd}>
                                    {this.state.kitWithKitSet.filter(
                                      (filterKit) =>
                                        filterKit._id ==
                                        sugKit.suggestedKitDetail[0]?._id
                                    ).length == 0 ? (
                                      <a
                                        className="mr-3 my-2 btn btn-small btn-default"
                                        href="javascript:;"
                                        onClick={() =>
                                          this.setSugestedSelectedOptionKit({
                                            value:
                                              sugKit.suggestedKitDetail[0]?._id,
                                            label:
                                              sugKit.suggestedKitDetail[0]
                                                ?.name,
                                          })
                                        }
                                      >
                                        {sugKit.suggestedKitDetail[0]?.name}{" "}
                                        <i className="fa fa-plus ml-2"></i>{" "}
                                      </a>
                                    ) : (
                                      <a
                                        disabled
                                        className="mr-3 my-2 btn btn-small btn-default"
                                        href="javascript:;"
                                      >
                                        {sugKit.suggestedKitDetail[0]?.name}{" "}
                                        <i className="fa fa-plus ml-2"></i>{" "}
                                      </a>
                                    )}
                                  </React.Fragment>
                                )
                              )}
                            </th>
                          </tr>
                        </React.Fragment>
                      )}
                      {this.state.kitWithKitSet &&
                        this.state.kitWithKitSet.map((kit, index) => (
                          <React.Fragment key={index}>
                            <tr>
                              <th>
                                {kit.name}
                                {kit.kitDocument.length > 0 && (
                                  <span className="ml-1">
                                    <a
                                      id={"UncontrolledTooltipPdf1" + index}
                                      target="_blank"
                                      rel="noreferrer"
                                      className="download-info badge1 badge-info1"
                                      href={
                                        process.env.REACT_APP_APIURL +
                                        "assets/uploads/kitDocuments/" +
                                        kit.kitDocument[0].name
                                      }
                                    >
                                      Download Tray List
                                    </a>
                                  </span>
                                )}
                              </th>
                              <td
                                style={{
                                  "vertical-align": "bottom",
                                  "padding-bottom": "5%",
                                }}
                              >
                                <label className="small">
                                  Quantity
                                  <span className="text-danger">*</span>
                                </label>
                                <Input
                                  type="number"
                                  min="0"
                                  className="form-control"
                                  value={kit.quantity}
                                  onChange={(e) =>
                                    this.handleKitQuantity(
                                      index,
                                      e.target.value
                                    )
                                  }
                                  name=""
                                  style={{
                                    width: "120px",
                                  }}
                                />
                                {kit.quantity == "" &&
                                error &&
                                this.state.quantityError ? (
                                  <span
                                    className="text-danger"
                                    style={{
                                      position: "absolute",
                                    }}
                                  >
                                    {error.kitData ? error.kitData : ""}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </td>
                              <td
                                style={{
                                  "vertical-align": "bottom",
                                  "padding-bottom": "5%",
                                }}
                              >
                                <label className="small">
                                  Hire Charges (Per Set)
                                </label>
                                <Input
                                  type="number"
                                  min="0"
                                  className="form-control"
                                  value={kit.hire_charge}
                                  onChange={(e) =>
                                    this.handleKitHireCharge(
                                      index,
                                      e.target.value
                                    )
                                  }
                                  name=""
                                  placeholder="£"
                                  style={{
                                    width: "120px",
                                  }}
                                />
                              </td>

                              <td className="text-right">
                                <div className="trash">
                                  <i
                                    onClick={() => this.handelRemovKit(index)}
                                    className="fa fa-trash mt-4"
                                  ></i>
                                </div>
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="d-card">
                <h4>Charges</h4>
                <div className="form-group">
                  <label>
                    Shipping Charge <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    min="0"
                    className="form-control"
                    value={this.state.updateBookingInfo.shipping_charge}
                    onChange={(e) =>
                      this.handleUpdateBookingInfo(
                        "shipping_charge",
                        e.target.value
                      )
                    }
                  />
                </div>
              </div>

              <div className="d-card mt-4">
                <h4>Booked By</h4>
                <div className="form-group">
                  <label>Additional Reps</label>
                  <Select
                    value={this.state.selectedAdditionalRep}
                    onChange={this.setSelectedOptionAdditionalRep}
                    options={this.state.additionalReps}
                    isMulti={true}
                  />
                </div>
              </div>

              <div className="d-card mt-4">
                <h4>Additional Notes</h4>
                <div className="form-group">
                  <textarea
                    className="form-control"
                    rows="4"
                    value={this.state.updateBookingInfo.additional_notes}
                    onChange={(e) =>
                      this.handleUpdateBookingInfo(
                        "additional_notes",
                        e.target.value
                      )
                    }
                  ></textarea>
                </div>
              </div>
              <div className="mt-5 text-right">
                {isLoading == false && (
                  <React.Fragment>
                    <Link
                      to="/reps/open-booking"
                      className="btn btn-secondary mb-3 mr-2"
                    >
                      Cancel
                    </Link>
                    <a
                      href="javascript:;"
                      onClick={() => this.openModalAndSubmit(true)}
                      className="btn mb-3 btn-primary"
                      disabled={!changesMade}
                    >
                      Save Changes
                    </a>
                  </React.Fragment>
                )}
                {isLoading == true && (
                  <React.Fragment>
                    <Link
                      to="/reps/open-booking"
                      className="btn btn-secondary mb-3 mr-2"
                    >
                      Cancel
                    </Link>
                    <a
                      className="btn btn-primary mb-3 loader-icon"
                      style={{ width: "150px" }}
                      disabled
                    >
                      <Spinner className="img-svg"></Spinner>
                    </a>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>

          {/* ADD FORM */}
        </div>

        <Modal
          size="lg"
          backdrop="static"
          isOpen={this.state.kitSetDetailModal}
          toggle={() => this.kitSetDetailModalClose()}
        >
          <ModalHeader
            className=""
            toggle={() => this.kitSetDetailModalClose()}
          >
            Kit Details
          </ModalHeader>
          <ModalBody>
            <table className="table">
              <thead>
                <tr>
                  <th>Item Code</th>
                  <th>Item Name</th>
                  <th>Qty</th>
                </tr>
              </thead>
              <tbody>
                {this.state.kitDetails?.setProductModules &&
                  this.state.kitDetails?.setProductModules.map(
                    (product, ind) => (
                      <tr key={ind}>
                        <td>{product.productID.code}</td>
                        <td>{product.productID.name}</td>
                        <td>{product.quantity}</td>
                      </tr>
                    )
                  )}
              </tbody>
            </table>
          </ModalBody>
          <ModalFooter>
            <div className="">
              <button
                className="btn btn-secondary"
                onClick={() => this.kitSetDetailModalClose()}
              >
                Close
              </button>
            </div>
          </ModalFooter>
        </Modal>
        <ToastContainer />
        {/* Set Details Modal */}

        <AddHospital
          addNewModalClose={this.addNewHospitalModalClose.bind(this)}
          addNewModal={this.state.addNewHospitalModalProp}
        />

        <UpdateHospital
          editModalClose={this.editHospitalModalClose.bind(this)}
          editModal={this.state.editHospitalModal}
          editId={this.state.editHospitalId}
          refreshGetList={this.refreshHospitalList.bind(this)}
        />

        <AddSurgeon
          addNewModalClose={this.addNewSurgeonModalClose.bind(this)}
          addNewModalProp={this.state.addSurgeonModal}
        />
        <SameDateModal
          show={this.state.sameDatesModal}
          sameDatesInfo={this.state.updateBookingInfo}
          saveChangeHandle={(flag) => {
            this.handleSubmit(flag);
          }}
          setProceed={(flag) => {
            this.setState({ proceed: flag }, () => {
              this.handleSubmit(flag);
            });
          }}
          onClose={() =>
            this.setState({
              sameDatesModal: false,
              checkDateFlag: false,
              sameDatesInfo: [],
            })
          }
          onSubmit={(flag) => {
            this.handleSubmit(flag);
          }}
          checkDateFlag={this.state.checkDateFlag}
          checkDateModalClose={this.checkDateModalClose?.bind(this)}
        />
        <CutOffWarningModal
          isOpen={this.state.showCutOffWarning}
          toggle={() => this.setState({ showCutOffWarning: false })}
        />

        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    bookingInfo,
    hospitalDetails,
    hospitalsList,
    repsList,
    error,
    redirect,
    kitSetList,
  } = state.rootReducer.repsBookings;
  const { kitListDropdown } = state.rootReducer.kits;
  const { editSet, set } = state.rootReducer.sets;
  const { opLists } = state.rootReducer.operations;

  return {
    hospitalsList,
    bookingInfo,
    hospitalDetails,
    repsList,
    error,
    redirect,
    kitListDropdown,
    kitSetList,
    editSet,
    set,
    opLists,
  };
}

export default connect(mapStateToProps)(Add);
