import React, { createRef, useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Input,
  UncontrolledTooltip,
} from "reactstrap";
import Select from "react-select";

import { ReactComponent as Spinner } from "../../../../../assets/images/Spinner-1s-200px.svg";

import { kitService, bookingService } from "../../../../../services";

const Edit = (props) => {
  const [editModal, setEditModal] = useState(false);
  const [name, setName] = useState("");
  const [kitDocs, setKitDocs] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [pdfname, setPdfname] = useState("");
  const [actual_name, setActual_name] = useState("");

  const [searchkits, setSearchKits] = useState("");
  const [kitList, setKitList] = useState([]);
  const [selectedlist, setSelectedList] = useState([]);
  const [page, setPage] = useState(1);
  const listInnerRef = createRef();
  const [samekit, setSameKit] = useState(false);
  const [additionalReps, setAdditionalReps] = useState([]);
  const [selectedRepId, setSelectedRepId] = useState([]);
  const [selectedAdditionalRep, setSelectedOptionAdditionalRep] = useState([]);
  const [randomSearch, setRandomSearch] = useState(0);

  const editModalOpen = () => {
    kitService
      .getById(props.id)
      .then((data) => {
        setName(data.data[0].name);
        setKitDocs("");
        setPdfname(
          data.data[0].kitDocument.length > 0
            ? data.data[0].kitDocument[0].original_name
            : ""
        );
        setSelectedRepId(data.data[0].additional_reps);
        setActual_name(
          data.data[0].kitDocument.length > 0
            ? data.data[0].kitDocument[0].name
            : ""
        );
        setEditModal(true);
        setLoading(false);
        setError(false);
        setSameKit(null);

        const selectedIdsArray = data.data[0].kitsuggestionData.map(
          (subItem) => subItem.kitsData[0]
        );

        console.log("selectedIdsArray", selectedIdsArray);
        setSelectedList(selectedIdsArray);
      })
      .catch((error) => {
        setLoading(false);
        setError(error.response?.data?.data?.errors);
      });
  };

  const editModalClose = () => {
    setEditModal(false);
    setName("");
    setKitDocs("");
    setError("");
    setPdfname("");
    setActual_name("");
    setSelectedList([]);
    props.editModalClose();
    setSearchKits("");
  };

  const refreshMainList = () => {
    setEditModal(false);
    setName("");
    setKitDocs("");
    setError("");
    setPdfname("");
    setActual_name("");
    setSelectedList([]);
    props.refreshMainList(false);
  };

  const nameHandler = (val) => {
    setName(val);
  };

  const fileHandler = (event) => {
    const file = event.target.files[0];
    setKitDocs(file);
  };

  const handleSubmit = () => {
    setLoading(true);
    const valuesArray = [];
    selectedAdditionalRep.forEach((item) => valuesArray.push(item.value));

    const formData = new FormData();
    formData.append("name", name);
    formData.append("kitDocs", kitDocs ? kitDocs : "false");
    formData.append("additional_reps", JSON.stringify(valuesArray));
    formData.append("id", props.id);
    formData.append("suggestedKits", JSON.stringify(selectedData));

    kitService
      .update(formData)
      .then((res) => {
        console.log(res);
        refreshMainList();
      })
      .catch((error) => {
        setLoading(false);
        setError(error.response.data.data.errors);
      });
  };

  const handleOnCustomProductSearch = (e) => {
    let searchString = e.target.value;
    setSameKit(false);
    setSearchKits(searchString);
    setPage(1);
    setRandomSearch(Math.floor(Math.random() * 200 + 1));
  };

  const getSearch = () => {
    kitService
      .getKitsSearchList({ search: searchkits, page: 1 })
      .then((res) => {
        handleKitList(res);
      });
  };

  const handleKitList = (kitList) => {
    if (page > 1) {
      setKitList((prevKitList) => [...prevKitList, ...kitList.data]);
    } else {
      setKitList(kitList.data);
    }
  };

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        // User has reached the bottom, load more data
        setPage((page) => page + 1);
        setRandomSearch(Math.floor(Math.random() * 200 + 1));
      }
    }
  };

  const handleOnKitSelect = (item) => {
    console.log("item", item);
    const isAlreadySelected = selectedlist.some(
      (selectedItem) => selectedItem._id === item._id
    );

    if (!isAlreadySelected) {
      setSelectedList((prevSelectedArr) => [item, ...prevSelectedArr]);
      setSameKit(false);
    } else {
      setSameKit(true);
    }
    setSearchKits("");
  };

  const handleRep = (repsList) => {
    const tempRepsList = repsList.map((item) => ({
      value: item._id,
      label: `${item.first_name} ${item.last_name}`,
    }));
    console.log("hello wwwww", tempRepsList, kitList);
    setAdditionalReps(tempRepsList);
  };

  const getRepList = () => {
    bookingService.getRepsList({ search: "" }).then((res) => {
      console.log("hhhh", res.data);
      handleRep(res.data);
    });
  };

  const deleteSelectedKit = (selectid) => {
    console.log(selectid);
    setSelectedList((prevSelectedArr) =>
      prevSelectedArr.filter((item) => item._id !== selectid)
    );
  };

  useEffect(() => {
    console.log("selectedRepId", selectedRepId, additionalReps);
    const filtered = additionalReps.length >0 && additionalReps.filter((item) =>
      selectedRepId?.includes(item.value)
    );

    setSelectedOptionAdditionalRep(filtered);
  }, [selectedRepId,additionalReps]);

  useEffect(() => {
    const selectedIdsArray = selectedlist.map(({ _id }) => ({
      suggestedKitId: _id,
    }));
    setSelectedData(selectedIdsArray);
  }, [selectedlist]);

  useEffect(() => {
    getSearch();
    setSameKit(false);
  }, [randomSearch]);

  useEffect(() => {
    if (props.editModal == true) {
      getRepList();
      editModalOpen();
      setRandomSearch(Math.floor(Math.random() * 200 + 1));
    }
  }, [props.editModal]);

  return (
    <div>
      <Modal
        size="md"
        className=""
        backdrop="static"
        isOpen={editModal}
        toggle={() => editModalClose()}
      >
        <ModalHeader className="" toggle={() => editModalClose()}>
          Edit Kit
        </ModalHeader>
        <ModalBody>
          <form className="">
            <div className="form-group row">
              <div className="col">
                <label>Kit Name</label>
                <Input
                  type="text"
                  className="form-control"
                  value={name}
                  onChange={(e) => nameHandler(e.target.value)}
                />
                {error && error ? (
                  <span className="text-danger">
                    {error.name ? error.name : ""}
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="form-group row">
              <div className="col">
                <label>Upload PDF (Maximum file size: 25 MB)</label>
                <Input
                  id="kitfile"
                  customLabel="Browse File"
                  name="file"
                  type="file"
                  accept=".pdf"
                  onChange={(e) => fileHandler(e)}
                />
                {error && error ? (
                  <span className="text-danger">
                    {error.kitDocs ? error.kitDocs : ""}
                  </span>
                ) : (
                  ""
                )}

                {pdfname && !kitDocs && (
                  <span>
                    <a
                      href={
                        process.env.REACT_APP_APIURL +
                        "assets/uploads/kitDocuments/" +
                        actual_name
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      {pdfname}
                    </a>
                  </span>
                )}
              </div>
            </div>
            <div className="form-group">
              <label>Additional Reps</label>
              <Select
                value={selectedAdditionalRep}
                onChange={setSelectedOptionAdditionalRep}
                options={additionalReps}
                isMulti={true}
              />
            </div>
            <div>
              <label>Add Suggested kits</label>
              {samekit ? (
                <span className="text-danger float-right">
                  This kit is already exist
                </span>
              ) : (
                ""
              )}
            </div>
            <div className="autocomplete">
              <div className="wrapper remove-rounder">
                <div className="sc-hKMtZM">
                  <input
                    type="text"
                    placeholder="Search..."
                    className="form-control"
                    value={searchkits}
                    onChange={handleOnCustomProductSearch}
                  />

                  {searchkits !== "" &&
                    kitList.filter((filKit) => filKit._id == props.id).length !=
                      kitList.length &&
                    kitList.length > 0 && (
                      <ul
                        className="suggestions"
                        onScroll={onScroll}
                        ref={listInnerRef}
                      >
                        {kitList &&
                          kitList
                            .filter((filKit) => filKit._id != props.id)
                            .map((item) => (
                              <li
                                key={item.id}
                                onClick={() => handleOnKitSelect(item)}
                              >
                                <i className="fa fa-search suggestionsSearch"></i>

                                <p>{item.name}</p>
                              </li>
                            ))}
                      </ul>
                    )}
                </div>
              </div>
            </div>
            {/* search input-box with dropdown */}
            {selectedlist.length > 0 && (
              <table className="table mt-1 table-scrollbar">
                <thead>
                  <tr>
                    <th className="border-top-0">Kit Name</th>
                    <th width="80" className="border-top-0">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {selectedlist.length > 0 &&
                    selectedlist.map((list, index) => (
                      <tr key={index}>
                        <td>{list.name}</td>
                        <td
                          width="80"
                          className={
                            selectedlist.length == 1
                              ? "text-center pd-5"
                              : "text-center"
                          }
                        >
                          <a
                            id={"UncontrolledTooltipDelete1" + index}
                            href="javascript:;"
                            onClick={() => deleteSelectedKit(list._id)}
                          >
                            <i className="fa fa-trash"></i>{" "}
                          </a>
                          <UncontrolledTooltip
                            target={"UncontrolledTooltipDelete1" + index}
                          >
                            Delete
                          </UncontrolledTooltip>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
          </form>
        </ModalBody>
        <ModalFooter>
          <div className="">
            <button
              onClick={() => editModalClose()}
              className="btn btn-secondary mr-2"
              data-dismiss="modal"
            >
              Cancel
            </button>
            {loading == false && (
              <button className="btn btn-primary" onClick={handleSubmit}>
                Update
              </button>
            )}
            {loading == true && (
              <button className="btn btn-primary loader-icon" disabled>
                <Spinner className="img-svg"></Spinner>
              </button>
            )}
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Edit;
